import React, { useEffect, Children } from "react";

// Components

const IconScroll: React.FC<{}> = ({}) => {
  const icons = [
    "atlassian.svg",
    "excel.svg",
    "google_calendar.svg",
    "google_drive.svg",
    "hubspot.svg",
    "sap.svg",
    "slack.svg",
  ];

  return (
    <div className="relative flex justify-center w-[30rem] h-[64px] overflow-hidden">
      <div className="absolute top-0 flex justify-center animate-scroll-loop">
        {icons.map((icon, index) => (
          <div key={index} className="flex justify-center pl-3 p4-3">
            <img
              src={`/app_icons/${icon}`}
              alt={icon}
              className="w-[64px] h-[64px]"
            />
          </div>
        ))}
      </div>
      <div className="absolute top-0 flex animate-scroll-loop2">
        {icons.map((icon, index) => (
          <div key={index} className="flex justify-center pl-3 p4-3">
            <img
              src={`/app_icons/${icon}`}
              alt={icon}
              className="w-[64px] h-[64px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const HalfAndHalf: React.FC<{
  children?: React.ReactNode;
  growLeft?: boolean;
  growRight?: boolean;
}> = ({ children, growLeft = false, growRight = false }) => {
  const childrenArray = React.Children.toArray(children);
  const [left, right] = childrenArray;
  const growLeftClass = growLeft ? "grow" : "";
  const growRightClass = growRight ? "grow" : "";
  const maxWidthLeftClass = growLeft ? "max-w-[50rem]" : "";
  const maxWidthRightClass = growRight ? "max-w-[50rem]" : "";
  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col md:flex-row w-full">
        <div className={`flex ${growLeftClass} justify-center bg-black`}>
          <div className={`flex grow ${maxWidthLeftClass}`}>{left}</div>
        </div>
        <div className={`flex ${growRightClass} justify-center bg-black`}>
          <div className={`flex grow ${maxWidthRightClass}`}>{right}</div>
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <h1 className="text-4xl max-w-4xl font-bold m-6">{children}</h1>;
};

const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <p className="text-md m-6">{children}</p>;
};

const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="flex flex-col items-center m-6">{children}</div>;
};

const FormLabel: React.FC<{ name: string; children: React.ReactNode }> = ({
  name,
  children,
}) => {
  return (
    <label htmlFor={name} className="block text-sm font-medium">
      {children}
    </label>
  );
};

const inputClasses =
  "mt-1 block w-full px-3 py-2 border border-gray-500 rounded-sm focus:outline-none focus:border-omegalight sm:text-sm bg-transparent";

const TextInput: React.FC<{ name: string; placeholder: string }> = ({
  name,
  placeholder,
}) => {
  return (
    <input
      type="text"
      name={name}
      className={inputClasses}
      placeholder={placeholder}
    />
  );
};

const TextAreaInput: React.FC<{ name: string; placeholder: string }> = ({
  name,
  placeholder,
}) => {
  return (
    <textarea
      id={name}
      name={name}
      className={inputClasses}
      placeholder={placeholder}
    />
  );
};

const FormSubmit: React.FC<{}> = ({}) => {
  return (
    <div className="text-right">
      <button
        type="submit"
        className="px-4 py-2 focus:outline-none bg-omegalight rounded-sm hover:bg-omegalight-700 focus:bg-omegalight-500"
      >
        Submit
      </button>
    </div>
  );
};

const HalfAndHalfImage: React.FC<{
  src: string;
  alt: string;
}> = ({ src, alt }) => {
  return <img className="flex object-fit lg:max-h-[40rem]" alt={alt} src={src} />;
};

// Sections

const TopNav: React.FC<{}> = ({}) => {
  return (
    <div className="p-6 flex flex-row justify-start w-full">
      <img
        alt="QueryOmgea Logo"
        className="h-10 flex"
        src={"omega_logo.png"}
      ></img>
    </div>
  );
};

const Banner: React.FC<{}> = ({}) => {
  return (
    <div className="bg-[#0b001c] w-full justify-end items-end flex flex-row overflow-hidden">
      <img
        src={"assets/omega_backdrop.png"}
        className="flex max-h-[40rem] object-fill"
        alt="QueryOmega Banner"
      />
    </div>
  );
};

const AutomateEverything: React.FC<{}> = ({}) => {
  return (
    <Section>
      <Header>Automate Everything. Everywhere. All at Once.</Header>
      <IconScroll />
      <Text>
        Connect effortlessly with any application you can imagine. QueryOmega's
        AI agents take charge, handling the complexity of creating and managing
        workflows for you. They adapt to your unique needs, ensuring seamless
        integration and automation while you enjoy a hassle-free experience. Let
        our AI agents do the heavy lifting, transforming your processes with
        limitless possibilities.
      </Text>
    </Section>
  );
};

const UlimitedAIPower: React.FC<{}> = ({}) => {
  return (
    <HalfAndHalf growRight={true}>
      <HalfAndHalfImage alt="AI Agent Army" src="assets/robots_purple.jpg" />
      <div className="flex flex-col">
        <Section>
          <Header>
            You don't need a new app when you can automate the ones you have.
          </Header>
          <Text>
            The current software ecosystem already covers every imaginable use
            case extensively. Instead of creating new applications, leverage
            existing tools and integrate AI and you can solve any problem ahead
            of you.
          </Text>
        </Section>
      </div>
    </HalfAndHalf>
  );
};

const EventHorizon: React.FC<{}> = ({}) => {
  return (
    <Section>
      <Header>Event Horizon</Header>
      <Text>
        Crossing the event horizon means stepping into a world where man and
        machine work together. AI agents are more than tools—they become the
        core of your workforce. Beyond traditional limits, QueryOmega’s AI
        agents execute tasks with unmatched precision and speed, transforming
        your operations. They push boundaries, accelerate processes, and free
        your talent for creativity and strategy. With AI as your ally, the
        future of automation is about achieving more together.
      </Text>
    </Section>
  );
};

const SignUp: React.FC<{}> = ({}) => {
  return (
    <HalfAndHalf growLeft={true}>
      <Section>
        <div className="flex grow flex-col">
          <Header>Cross The Event Horizon</Header>
          <form
            action="https://formspree.io/f/meojdkkp"
            method="POST"
            className="space-y-6 p-6"
          >
            <div>
              <FormLabel name={"email"}>Email</FormLabel>
              <TextInput name="email" placeholder="you@example.com" />
            </div>

            <div>
              <FormLabel name="message">
                What do you need AI agents to achieve?
              </FormLabel>
              <TextAreaInput
                name="message"
                placeholder="Describe your goals..."
              />
            </div>
            <FormSubmit />
          </form>
        </div>
      </Section>
      <HalfAndHalfImage
        alt="Technology Wormhole Singularity"
        src="assets/wormhole_purple.webp"
      />
    </HalfAndHalf>
  );
};

function App() {
  return (
    <div className="items-center bg-omegadark text-white">
      <TopNav />
      <Banner />
      <AutomateEverything />
      <UlimitedAIPower />
      <EventHorizon />
      <SignUp />
      <footer className="mt-auto py-4 text-center">
        <p>&copy; 2024 OmegaDB, Inc. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
